import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import cardoneImg from '../assets/cardone.jpg';
import cardtwoImg from '../assets/cardtwo.jpg';
import cardthreeImg from '../assets/cardthree.jpg';
import '../App.css';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';


//import ModalGallery from './ModalGallery/ModalGallery';


export default function Gallery() {
    //const [modalActive, setModalActive] = useState(true)  //добавим состоряние отвечающее за видимость окна
    
    return (
        <>
            <Container>
                <Row className='mt-5'> 
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>                     
                        <ReactFancyBox
                            thumbnail={cardoneImg}
                            image={cardoneImg}
                            
                        />                                           
                    </Col>                    
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardtwoImg}
                            image={cardtwoImg}
                        />                                               
                    </Col>
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardthreeImg}
                            image={cardthreeImg}
                        />  
                    </Col>                    
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardoneImg}
                            image={cardoneImg}
                        />   
                    </Col>
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardtwoImg}
                            image={cardtwoImg}
                        />   
                    </Col>
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardthreeImg}
                            image={cardthreeImg}
                        /> 
                    </Col> 
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardoneImg}
                            image={cardoneImg}
                        /> 
                    </Col>
                    <Col className='col-lg-3 col-md-4 col-6 mb-3' style={{cursor: 'pointer'}}>
                        <ReactFancyBox
                            thumbnail={cardtwoImg}
                            image={cardtwoImg}
                        /> 
                    </Col>                   
                </Row>
            </Container>               
        </>
    )
}

//<ModalGallery active={modalActive} setActive={setModalActive}/> 

//                    <Col className='col-lg-3 col-md-4 col-6 mb-3'>
//                        <ModalGallery active={modalActive} setActive={setModalActive}>
//                            <img  className='img-fluid' src={cardoneImg}/>
//                        </ModalGallery> 
//                        <button className='open-btn' onClick={() => setModalActive(true)}>
//                            <img className='img-fluid' src={cardoneImg}/>                           
 //                       </button>                                          
 //                   </Col>