import React, { useState } from 'react';
import { Button, Container, Form, Modal, Nav, Navbar } from 'react-bootstrap';
import { Link, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Home from './Pages/Home';
import Users from './Pages/Users';
import styled from 'styled-components'; //стилизация компонентов как в бутстрапе, только с помощью компонентов этой библиотеки

//стилизация компонентов как в бутстрапе - заменим на свои стили для ссылок и ховер-эффекта в навбаре
const Styles = styled.div `
     a, .navbar-brand, .navbar-nav .nav-link {
         color: #adb1b8;
         &:hover {
             color: white
         }
     } 
`


export default function NaviBar() {

    //создадим функцию для модального окна
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 

    return (
        <>
        <Styles>  
            <Navbar collapseOnSelect expand='md' bg='primary' variant="dark">
                <Container>
                    <Link className='navbar-brand' to='/'>ReactSite</Link>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='mr-auto'>
                            <Link className='nav-link' to='/'>Home</Link>
                            <Link className='nav-link' to='/users'>Users</Link>
                            <Link className='nav-link' to='/about'>About</Link>
                        </Nav>
                        <Nav>
                            <Button variant='success' className='mr-1' onClick={handleShow}>Log In</Button>
                            <Button variant='warning' onClick={handleShow}>Sign out</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>                    
                <Route exact path="/" element={<Home />} />
                <Route exact path="/users" element={<Users/>} />
                <Route exact path="/about" element={<About/>} />                    
            </Routes> 
        </Styles>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Log in</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId='fromBasicEmail'>
                        <Form.Label>Email Addres</Form.Label>
                        <Form.Control type='email' placeholder='Enter email'/>
                        <Form.Text className='text-muted'>We'll never share your email with anyone else</Form.Text>
                    </Form.Group>
                    <Form.Group controlId='fromBasicPassword'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter password'/>
                    </Form.Group>
                    <Form.Group controlId='fromBasicCheckbox'>
                        <Form.Check className='checkbox' label='Remember me'/>
                    </Form.Group>
                    <div className='d-flex justify-content-end'>
                        <Button variant='primary' type='submit'>Submit</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );
}