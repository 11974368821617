import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NaviBar from './Components/NaviBar';
import Footer from './Components/Footer';

function App() {
  return (
    <>
       <NaviBar/>
       <Footer/>
    </>
  );
}

export default App;
