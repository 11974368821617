import React, { useState } from 'react';
import '../../App.css';
import Gallery from '../Gallery';
import Tabes from '../Tabes';
import Paralaxes from '../Parallaxes';


//import ModalGallery from '../ModalGallery/ModalGallery';



export default function About() {
  //const [modalActive, setModalActive] = useState(false)  //добавим состоряние отвечающее за видимость окна
    return (
        <>
          <Paralaxes/>
          <Gallery/>
          <Tabes/>
        </>
    )
}

//<ModalGallery active={modalActive} setActive={setModalActive}/>