import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Cards from '../Cards';
import Slider from '../Slider';
import Paralaxes from '../Parallaxes';
import ContentHome from '../ContentHome';
import Video from '../Video';

export default function Home() {
    return (
        <>
           <Slider/>           
           <Paralaxes/>
           <Cards/>
           <ContentHome/>
           <Video/>
        </>
    )
}