import React from 'react';
import { Container, Row } from 'react-bootstrap';


export default function Video() {
      return (
          <>
            <Container>
                <Row style={{marginBottom: '70px'}}>
                    <iframe 
                        width="100%" height="315" 
                        src="https://www.youtube.com/embed/cvC87pg_wWk" 
                        title="YouTube video player" 
                        frameborder="0" allow="accelerometer;
                        autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                    >
                    </iframe>
                </Row>
            </Container>
          </>
      )
  }








