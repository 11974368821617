import React from 'react';
import { Container } from 'react-bootstrap';

export default function Footer() {
    return (
        <>
        
           <Container fluid className='fixed-bottom' style={{backgroundColor: '#212529', color: '#fff'}}>
               <Container style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                   <p>Developers ocean</p>
               </Container>
           </Container>
           
        </>
    )
}