import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { render } from "react-dom";                   //для разных вариантов параллакса     
import { Background, Parallax } from 'react-parallax';
import parallaxphotoImg from '../assets/parallaxphoto.jpg';

//для разных вариантов параллакса
const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "20px",
    opacity: "0.7",
    color: "blue"
  };

const Paralaxes = () => (
  <Parallax
      bgImage={parallaxphotoImg}
      strength={500}
      renderLayer={(percentage) => (
        <div>
          <div
            style={{
              position: "absolute",
              background: `rgba(255, 125, 0, ${percentage * 0.5})`,
              left: "50%",
              top: "50%",
              borderRadius: "50%",
              transform: "translate(-50%,-50%)",
              width: percentage * 200,
              height: percentage * 200
            }}
          />
        </div>
      )}
    >
      <div style={{ height: '100vh' }}>
        <div style={insideStyles}>Parallax effects</div>
      </div>
    </Parallax>
);
export default Paralaxes;

//
//<Parallax
//    blur={{ min: -50, max: 50 }}
//    bgImage={require('.././assets/slidetwo.jpg')}
//    bgImageAlt="the dog"
 //   strength={-70}
//>
//   <p style={{textAlign: 'center', marginTop: '30%', marginBottom: '30%', color: 'aqua'}}>Blur transition from min to max</p>
//    <div style={{ height: '50px' }}  />
//</Parallax>

//
//<Parallax strength={500} bgImage={parallaxphotoImg}>
 //       <Background className="custom-bg" >            
                    
 //       </Background>
 //      <p style={{textAlign: 'center', marginTop: '50%', marginBottom: '50%', color: 'aqua'}}>Blur transition from min to max</p>   
 //   </Parallax>

//
//<Parallax strength={500}>
  //      <Background className="custom-bg">            
  //          <img src={slidefiveImg} alt="fill murray" />        
   //     </Background>
   //     <p style={{textAlign: 'center', marginTop: '50%', marginBottom: '50%', color: 'aqua'}}>Blur transition from min to max</p>   
   // </Parallax>
   
 //
   //<Parallax bgImage={slidefiveImg} blur={{ min: -10, max: 10 }}>
    //  <div style={{ height: '100vh' }}>
     //   <div style={insideStyles}>Dynamic Blur</div>
     // </div>
   // </Parallax>

//
//<Parallax
 //     bgImage={slidefiveImg}
 //     strength={500}
//      renderLayer={(percentage) => (
//        <div>
//          <div
 //           style={{
 //             position: "absolute",
 //             background: `rgba(255, 125, 0, ${percentage * 0.5})`,
 //             left: "50%",
 //             top: "50%",
  //            borderRadius: "50%",
 //             transform: "translate(-50%,-50%)",
 //             width: percentage * 200,
  //            height: percentage * 200
 //           }}
 //         />
 //       </div>
 //     )}
 //   >
 //     <div style={{ height: '100vh' }}>
 //       <div style={insideStyles}>Parallax effects</div>
 //     </div>
 //   </Parallax>