import React from 'react';
import { Carousel } from 'react-bootstrap';
import slideoneImg from '../assets/slideone.jpg';
import slidetwoImg from '../assets/slidetwo.jpg';
import slidethreeImg from '../assets/slidethree.jpg';
import slidefourImg from '../assets/slidefour.jpg';
import slidefiveImg from '../assets/slidefive.jpg';

export default function Slider() {
    return (        
            <Carousel>
                <Carousel.Item style={{'height': '89vh'}}>
                    <img
                        className='d-block w-100'
                        src={slideoneImg}
                        alt='Slide photo'
                    />
                    <Carousel.Caption>
                        <h3>Ocean image</h3>
                        <p>Lorem ipsum dolor, sit amet consectetur adipising elit.Quidem, est?</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{'height': '89vh'}}>
                    <img
                        className='d-block w-100'
                        src={slidetwoImg}
                        alt='Slide photo'
                    />
                    <Carousel.Caption>
                        <h3>Ocean image</h3>
                        <p>Lorem ipsum dolor, sit amet consectetur adipising elit.Quidem, est?</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{'height': '89vh'}}>
                    <img
                        className='d-block w-100'
                        src={slidethreeImg}
                        alt='Slide photo'
                    />
                    <Carousel.Caption>
                        <h3>Ocean image</h3>
                        <p>Lorem ipsum dolor, sit amet consectetur adipising elit.Quidem, est?</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{'height': '89vh'}}>
                    <img
                        className='d-block w-100'
                        src={slidefourImg}
                        alt='Slide photo'
                    />
                    <Carousel.Caption>
                        <h3>Ocean image</h3>
                        <p>Lorem ipsum dolor, sit amet consectetur adipising elit.Quidem, est?</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{'height': '89vh'}}>
                    <img
                        className='d-block w-100'
                        src={slidefiveImg}
                        alt='Slide photo'
                    />
                    <Carousel.Caption>
                        <h3>Ocean image</h3>
                        <p>Lorem ipsum dolor, sit amet consectetur adipising elit.Quidem, est?</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>        
    )
}