import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import contentHomeImg from '../assets/contentHome.jpg';
import slidefiveImg from '../assets/slidefive.jpg';

export default function ContentHome() {
    return (
        <>
           <Container>
               <Row className='mb-5'>
                   <Col md={7}>
                       <img src={contentHomeImg} style={{'height': '400px', 'maxWidth': '100%'}} />
                   </Col>
                   <Col md={5}>
                       <h2>Developers ocean</h2>
                       <p>
                            Lorem Ipsum is simply dummy text of the printing 
                            and typesetting industry. Lorem Ipsum has been the industry's 
                            standard dummy text ever since the 1500s, when an unknown printer 
                            took a galley of type and scrambled it to make a type specimen book. 
                            It has survived not only five centuries, but also the leap into electronic 
                            typesetting, remaining essentially unchanged. It was popularised in the 1960s 
                            with the release of Letraset sheets containing Lorem Ipsum passages, and more 
                            recently with desktop publishing software like Aldus PageMaker including versions 
                            of Lorem Ipsum.
                       </p>
                   </Col>
               </Row>
               <Row className='mb-5'>                   
                   <Col md={5}>
                       <h2>Developers ocean</h2>
                       <p>
                            Lorem Ipsum is simply dummy text of the printing 
                            and typesetting industry. Lorem Ipsum has been the industry's 
                            standard dummy text ever since the 1500s, when an unknown printer 
                            took a galley of type and scrambled it to make a type specimen book. 
                            It has survived not only five centuries, but also the leap into electronic 
                            typesetting, remaining essentially unchanged. It was popularised in the 1960s 
                            with the release of Letraset sheets containing Lorem Ipsum passages, and more 
                            recently with desktop publishing software like Aldus PageMaker including versions 
                            of Lorem Ipsum.
                       </p>
                   </Col>
                   <Col md={7}>
                       <img src={slidefiveImg} style={{'height': '400px', 'maxWidth': '100%'}} />
                   </Col>
               </Row>
           </Container>
        </>
    )
}