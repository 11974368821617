import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import cardoneImg from '../assets/cardone.jpg';
import cardtwoImg from '../assets/cardtwo.jpg';
import cardthreeImg from '../assets/cardthree.jpg';
import cardfourImg from '../assets/cardfour.jpg';

export default function Cards() {
    return (        
        <Container className='mt-4 mb-4'>
            <Row>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardoneImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardtwoImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardthreeImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardfourImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardthreeImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardoneImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardoneImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardtwoImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-4' style={{width: '18rem', margin: '0 auto'}}>
                        <Card.Img
                            variant='top'
                            src={cardthreeImg}
                        />
                        <Card.Body>
                            <Card.Title>Developers ocean</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Voluptas fugiat laboriasam dicta.
                            </Card.Text>
                            <Button variant='primary'>About team</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>   
    )
}