import React, { Component } from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import taboneImg from '../assets/tabone.jpg';
import tabtwoImg from '../assets/tabtwo.jpg';
import tabthreeImg from '../assets/tabthree.jpg';
import tabfourImg from '../assets/tabfour.jpg';
import tabfiveImg from '../assets/tabfive.jpg';
import '../App.css';

export default class Tabes extends Component {
    render() {
        return (
            <Container>
                <Tab.Container id='ledt-tabs-example' defaultActiveKey='first'>
                    <Row style={{marginTop: '50px'}}>
                        <Col sm={3} style={{marginBottom: '20px'}}>
                            <Nav variant='pills' className='flex-column'>
                                <Nav.Item style={{cursor: 'pointer'}}>
                                    <Nav.Link className='nav-link_link' eventKey='first'>Design</Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{cursor: 'pointer'}}>
                                    <Nav.Link className='nav-link_link' eventKey='second'>Team</Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{cursor: 'pointer'}}>
                                    <Nav.Link className='nav-link_link' eventKey='third'>Programming</Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{cursor: 'pointer'}}>
                                    <Nav.Link className='nav-link_link' eventKey='fourth'>Frameworks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{cursor: 'pointer'}}>
                                    <Nav.Link className='nav-link_link' eventKey='fifht'>Libraries</Nav.Link>
                                </Nav.Item> 
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey='first' style={{marginBottom: '70px'}}>                                
                                    <div style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        <img style={{width: '100%', objectFit: 'cover'}} src={taboneImg}/> 
                                    </div>                                    
                                    <p style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla!
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla! 
                                    </p>  
                                </Tab.Pane>
                                <Tab.Pane eventKey='second' style={{marginBottom: '70px'}}>
                                    <div style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        <img style={{width: '100%', objectFit: 'cover'}} src={tabtwoImg}/> 
                                    </div>                                    
                                    <p style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla! 
                                    </p>  
                                </Tab.Pane>
                                <Tab.Pane eventKey='third' style={{marginBottom: '70px'}}>
                                    <div style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        <img style={{width: '100%', objectFit: 'cover'}} src={tabthreeImg}/> 
                                    </div>                                     
                                    <p style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla! 
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla!
                                    </p>  
                                </Tab.Pane>
                                <Tab.Pane eventKey='fourth' style={{marginBottom: '70px'}}>
                                    <div style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        <img style={{width: '100%', objectFit: 'cover'}} src={tabfourImg}/> 
                                    </div>                                    
                                    <p style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.                                    
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla! 
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                    </p>  
                                </Tab.Pane>
                                <Tab.Pane eventKey='fifht' style={{marginBottom: '70px'}}>
                                    <div style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                                        <img style={{width: '100%', objectFit: 'cover'}} src={tabfiveImg}/> 
                                    </div>                                    
                                    <p style={{maxWidth: '450px', width: '100%', margin: '0 auto'}}>                                    
                                        Quae illum placeat quisquam, eaque quia vero neque, itaque ducimus cum 
                                        quasi doloribus aperiam eos dignissimos, eum labore esse. Eligendi, veritatis nulla! 
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </p>  
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        )
    }
}
